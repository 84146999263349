<template>
  <div class="pageWrapper">
    <HeadNew
        title="Сообщения"
    />

    <div class="main_container tabs">
      <button
          :class="{ selected: state.tab === 'all' }"
          @click="router.replace({name: 'ChatList', query: {tab: 'all'}})"
      >
        Все
      </button>
      <button
          :disabled="unreadCount === 0"
          :class="{ selected: state.tab === 'unread', disabled: unreadCount === 0}"
          @click="router.replace({name: 'ChatList', query: {tab: 'unread'}})"
      >
        Непрочитанные
        <span class="redCircle" v-if="unreadCount">
          {{ unreadCount }}
        </span>
      </button>
    </div>

    <div class="pageWrapperOverflow">
      <div class="chatList main_container" style="padding: 0">
        <ChatLine
            v-if="!state.isLoading"
            v-for="chat in tabChats"
            :key="chat.id"
            :chat="chat"
        />
        <ChatLine
            v-if="state.isLoading"
            :is-loading="true"
            v-for="_ in 8"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue";

import {computed, onBeforeMount, reactive, toRaw, watch} from 'vue'
import {appAxios} from '@/axios'
import ChatLine from '@/views/chat/ChatLine.vue'
import store from "@/store/store.js"
import {indexedStore} from "@/indexedDB/indexedStore.js"
import {router} from "@/router/router.js"
import {useRoute} from "vue-router";

const route = useRoute()

const state = reactive({
  tab: route.query.tab ?? 'all',
  isLoading: true,
  chats: [],
})

const unreadCount = computed(() => {
  let unreadMessages = 0

  state.chats.forEach(chat => {
    unreadMessages += chat.unchecked_messages_count
  })

  if (unreadMessages) {
    const num = Number(unreadMessages)
    if (num > 99) {
      return `99+`
    }
  }
})

const tabChats = computed(() => {
  switch (state.tab) {
    case 'all':
      return state.chats
    case 'unread':
      return state.chats.filter(chat => {
        return chat.unchecked_messages_count
      })
  }
})

async function checkProfilesMessages(uncheckedMessagesValue) {
  const uncheckedMessages = JSON.parse(JSON.stringify(toRaw(uncheckedMessagesValue)))
  let needUpdate = false
  state.chats.forEach(chat => {
    if(!chat.last_message) {
      return
    }
    chat.last_message.checked_at = chat.last_message.created_at
  })
  uncheckedMessages.forEach(item => {
    const id = item.chat_id
    const found = state.chats.find(chat => {
      return String(chat.id) === String(id)
    })
    if (found) {
      found.unchecked_messages_count = item.count
      if (item.count === 0) {
        if(found.last_message) {
          found.last_message.checked_at = found.last_message.created_at
        }
      } else {
        found.last_message = item.last_message
      }
    } else {
      if (item.count > 0) {
        needUpdate = true
      }
    }
  })
  if (needUpdate) {
    await indexedStore.slices.base.invalidate('chat-fetchAll')
    const res = await appAxios.chat.fetchAll()
    const chats = res.data
    state.chats = [
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
      ...chats
          .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
          .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
    ]
  }
}

watch(() => store.state.user.profile.unchecked_messages, async uncheckedMessages => {
  await checkProfilesMessages(uncheckedMessages)
})

onBeforeMount(async () => {
  const res = await appAxios.chat.fetchAll()
  const chats = res.data
  state.chats = [
    ...chats
        .filter(chat => store.state.user.directories.support_chat_user.id === chat.user_from.id),
    ...chats
        .filter(chat => store.state.user.directories.support_chat_user.id !== chat.user_from.id && chat.last_message)
        .sort((a, b) => new Date(b.last_message?.created_at) - new Date(a.last_message?.created_at))
  ]
  await checkProfilesMessages(store.state.user.profile.unchecked_messages)
  state.isLoading = false

  if (state.tab === 'unread' && unreadCount.value === 0) {
    await router.replace({name: 'ChatList', query: {tab: 'all'}})
  }
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  margin-bottom: 80px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.chatList {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  padding: 0;

  button {
    width: 100%;
    background: #171717;
    height: 42px;
    font-size: 14px;
    border-bottom: 2px #494949 solid;
    color: #FFFFFF;

    &.disabled {
      color: #A6A5A5;
    }

    &.selected {
      border-bottom: 2px #CF2E2E solid;
    }
  }
}

.redCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  background: #CF2E2E;
  margin-left: 4px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

</style>
