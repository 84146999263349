<script setup>
import {onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, toRef} from 'vue'
import {useField} from 'vee-validate'
import {vMaska} from "maska"

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  textInfo: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // mask
  mask: {
    type: String,
    default: '',
  },
  pattern: {
    type: String,
    default: '',
  },
  showMask: {
    type: String,
    default: '',
  },
  paste: {
    type: Function,
  },
  dataMaskaTokens: {
    type: String,
  },
  dataMaskaEager: {
    type: Boolean,
    default: false,
  }
})


// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')
const inputRef = ref(null)
const maskRef = ref(null)

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const state = reactive({
  focused: false,
})

const events = [
  'input',
  'keydown',
  'keyup',
  'focus',
  'blur',
  'click',
  'change',
  'paste',
  'cut',
  'scroll',
  'wheel',
  'dragover',
]

onMounted(() => {
  if (props.showMask) {
    events.forEach(eventName => {
      inputRef.value.addEventListener(eventName, (event) => {
        maskRef.value.scrollLeft = event.target.scrollLeft
      })
    })
  }
})

// onBeforeUnmount(() => {
//   if (props.showMask) {
//     events.forEach(eventName => {
//       inputRef.value.removeEventListener(eventName)
//     })
//   }
// })

function onBlur(event) {
  state.focused = false
  handleBlur(event)
  if (props.showMask) {
    if (inputValue.value === props.showMask) {
      handleChange('')
    }
  }
}

function onFocus() {
  state.focused = true
  if (props.showMask) {
    if (!inputValue.value) {
      handleChange(props.showMask)
    }
  }
}

function onPaste(e) {
  if(props.paste) {
    props.parse(e)
    return
  }
  let paste = (e.clipboardData || window.clipboardData).getData('text')
  let cleared = paste.trim()
  handleChange(cleared)
}

function onChange(event) {
  event.preventDefault()
  event.stopPropagation()
  if (props.showMask) {
    if (event.target.value.length < props.showMask.length && state.focused) {
      handleChange(props.showMask)
      return
    }
  }
  handleChange(event)
}

function onKeydown(event) {
  if(props.showMask && event.target.selectionStart < props.showMask.length) {
    event.target.focus()
    event.target.setSelectionRange(props.showMask.length, Math.max(props.showMask.length, event.target.selectionEnd))
    return;
  }
  if (props.showMask) {
    if (event.target.value.length - 1 < props.showMask.length && (event.key === "Backspace" || event.key === "Delete")) {
      event.preventDefault()
      event.stopPropagation()
      handleChange(props.showMask)
      return
    }
  }
  handleChange(event)
}

</script>

<template>
  <div
      class="textInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: fullWidth }"
  >
    <label :for="name">
      {{ label }}
    </label>
    <div class="inputWrapper">
      <div v-if="showMask" class="mask" ref="maskRef">
        <span class="left">
          {{ showMask }}
        </span>
        <span class="right">
          {{ inputValue.replace(showMask, '') }}
        </span>
      </div>
      <input
          ref="inputRef"
          :name="name"
          :id="name"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          @input="onChange"
          @keydown="onKeydown"
          @blur="onBlur"
          @focus="onFocus"
          @paste.prevent="onPaste"
          :inputmode="inputmode"
          :pattern="pattern"
          :disabled="disabled"
          v-maska
          :data-maska="mask"
          :data-maska-tokens="dataMaskaTokens"
          :data-maska-eager="dataMaskaEager"
      />
      <!--      <div class="error_icon" v-if="true">-->
      <!--        <img src="@/assets/images/error_input.svg" alt=""/>-->
      <!--      </div>-->
    </div>
    <!--    v-show="errorMessage || meta.valid"-->
    <div v-if="textInfo && !errorMessage" class="textInfo">
      {{ textInfo }}
    </div>
    <div class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/variables";

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: $color-11;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    .mask {
      .left {
        color: #838181;
      }
    }

    .mask,
    input {
      box-sizing: border-box;
      width: 100%;
      border-radius: 12px;
      color: rgb(211, 210, 210);
      border: 1px solid $color-13;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      padding: 14px 12px;
      caret-color: rgb(211, 210, 210);

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:disabled {
        color: #838181;
        border: 1px solid #303030;
      }

      &:focus {
        border: 1px solid $color-5;
      }
    }

    .mask + input {
      color: transparent;
      caret-color: rgb(211, 210, 210);
    }

    .mask {
      border: 1px solid transparent !important;
      position: absolute;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      .left {
        color: #838181;
      }
    }
  }

  .inputError {
    border: 1px solid $color-5 !important;
  }

  .errorMessage {
    color: rgb(255, 48, 58);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: $color-1;
  border: 1px solid $color-13;

  &:focus {
    border: 1px solid $color-5;
  }
}

//.inputWrapper:before {
//  content: ' ';
//  position: absolute;
//  width: 12px;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  display: block;
//  background: red;
//  border: 1px solid transparent;
//  border-radius: 12px 0 0 12px;
//}
//
//.inputWrapper:after {
//  content: ' ';
//  position: absolute;
//  width: 12px;
//  top: 0;
//  bottom: 0;
//  right: 0;
//  display: block;
//  background: red;
//  border: 1px solid transparent;
//  border-radius: 0 12px 12px 0;
//}

//@media only screen and (max-width: 992px) {
//  .TextInput input {
//    height: 56px;
//    padding: 0px 16px;
//  }
//}
</style>
