<template>
  <div v-if="state.currentStepIndex" class="pageWrapper">
    <head-new
        :back-action="state.currentStepIndex > 1 ? back : undefined"
        title="Регистрация"
    />
    <div class="main_container pageWrapperOverflow">

      <div class="steps">
        <Typography variant="body2" color="#A6A5A5">
          Шаг {{ state.currentStepIndex }} из {{ maxSteps }}
        </Typography>
        <div class="logo"/>
      </div>

      <BarProgress
          :completed="state.currentStepIndex"
          :barCount="maxSteps"
          style="margin-bottom: 24px"
      />

      <component
          :is="OnboardingSteps[state.currentStepIndex]"
          :nextStep="nextStep"
          :createUserStepIfNotExist="createUserStepIfNotExist"
          :userSteps="userSteps"
          style="flex-grow: 1;  display: flex;  flex-direction: column;"
      />
    </div>
  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import Typography from '@/components/UI/Typography.vue'
import BarProgress from '@/components/BarProgress.vue'
import {OnboardingSteps} from '@/views/profile/onboarding/OnboardingSteps.js'
import {computed, onBeforeMount, reactive, toRaw} from 'vue'
import store from '@/store/store.js'
import {appAxios} from '@/axios.js'
import {router} from '@/router/router.js'
import {yandexMetrika} from "@/external/yandexWebisor.js";

const state = reactive({
  currentStepIndex: 0,
})

const maxSteps = computed(() => store.state.user.directories.onboarding_step_list.length)
const userSteps = computed(() => store.state.user.profile.onboarding)

function back() {
  state.currentStepIndex--
}

async function createUserStepIfNotExist(stepIndex) {
  if (!userSteps.value.find(us => us.step === stepIndex)) {
    return await appAxios.onboarding.start(stepIndex)
  }
}

async function nextStep() {
  const found = userSteps.value.find(us => us.step === state.currentStepIndex)
  if (found) {
    await appAxios.onboarding.finish(found.id)
  } else {
    yandexMetrika('reachGoal', 'onboardingStep', {
      step: state.currentStepIndex,
    })
    const res = await createUserStepIfNotExist(state.currentStepIndex)
    await appAxios.onboarding.finish(res.data.id)
  }
  if (state.currentStepIndex + 1 === maxSteps.value + 1) {
    await store.commit('user/setProfile', {
      onboarding_finished: true,
    })
    // await router.push({name: 'CheckList'})
    await router.push({name: 'Home'})
    return
  }
  state.currentStepIndex++
}

onBeforeMount(async () => {
  let currentStep = 0
  store.state.user.profile.onboarding.forEach((us) => {
    if (us.finished_at) {
      currentStep = Math.max(us.step, currentStep)
    }
  })
  state.currentStepIndex = Math.min(currentStep + 1, store.state.user.directories.onboarding_step_list.length)
})

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  padding-bottom: 24px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > * {
    flex-shrink: 0;
  }
}

.steps {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 12px;

  .logo {
    width: 96px;
    height: 24px;
    position: relative;
    background: url("@/assets/images/light_logo.svg") no-repeat;
    background-size: contain;
  }
}


</style>
