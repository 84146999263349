import OneSignal from 'onesignal-cordova-plugin'

window.CordovaOneSignal = OneSignal

setTimeout(() => {
  OneSignal.initialize("f6cfffc4-9380-462f-ac87-1c0e0d7c8f30")
  OneSignal.Notifications.requestPermission()
})

export const AppOneSignal = {}
