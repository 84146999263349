<template>
  <div class="pageWrapper">
    <head-new
        :page_title="state.page !== 'final' ? 'Отзыв о курсе' : undefined"
        :back-action="back"
    />

    <div
        class="main_container pageWrapperOverflow"
    >
      <div style="margin-bottom: 24px"/>

      <template v-if="state.page === 'final'">
        <div style="flex-grow: 1"/>
        <div class="main_container final">
          <div class="finalIcon"/>
          <div class="finalText">
            <Typography variant="v20-700" center style="margin-bottom: 8px">
              Спасибо за отзыв!
            </Typography>
            <Typography variant="v14-400" color="#D3D2D2" center>
              Наши продукты станут лучше благодаря тебе
            </Typography>
          </div>
        </div>
        <div style="flex-grow: 1"/>
      </template>
      <template v-else>
        <div
            v-if="state.error"
            style="display: flex; gap: 4px; margin-bottom: 8px"
        >
          <Icon
              style="background-size: cover; flex-shrink: 0"
              width="16"
              height="16"
              :icon="require(`@/assets/icons/_images/16_redCircleError.svg`)"
          />
          <Typography variant="v12-500" color="rgb(255, 48, 58)">
            {{ state.error }}
          </Typography>
        </div>

        <template v-if="state.page === 'score'">
          <Card
              v-for="review in state.scoreReview"
              style="margin-bottom: 16px"
          >
            <Typography
                variant="v14-500"
                style="margin-bottom: 16px"
            >
              {{ review.title }}
            </Typography>

            <div class="scoreBtnWrapper">
              <AppButton
                  v-for="(_, index) in (review.max_integer_grade - review.min_integer_grade + 1)"
                  class="score"
                  :class="{scoreActive: index < state.userScoreReview[review.id]}"
                  size="tiny"
                  variant="secondary"
                  style="border: 1px transparent solid;"
                  @click="setScoreReview(review.id, index + review.min_integer_grade)"
              >
                {{ index + review.min_integer_grade }}
              </AppButton>
            </div>
          </Card>
        </template>

        <template v-if="state.page === 'text'">
          <template
              v-for="review in state.textReviews"
          >
            <FormTextAreaGrowable
                class="textArea"
                :label="review.title"
                :name="`answer-${review.id}`"
                :rows="4"
                growable
            />
          </template>
        </template>

        <AppButton
            style="margin-top: 12px; margin-bottom: 0"
            size="large"
            full-width
            :loading="state.isSubmitting"
            :disabled="state.isSubmitting"
            @click="onSubmit"
        >
          <template v-if="state.page === 'score' && state.isTextPageExists">
            Продолжить
          </template>
          <template v-else>
            Отправить
          </template>
        </AppButton>
      </template>
    </div>
  </div>
</template>

<script setup>
import {router} from '@/router/router'
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, reactive} from "vue"
import {appAxios} from "@/axios.js"
import store from "@/store/store.js";
import Card from "@/components/UI/Card.vue";
import Typography from "@/components/UI/Typography.vue";
import AppButton from "@/components/UI/AppButton.vue";
import Icon from "@/components/UI/Icon.vue";
import FormTextArea from "@/components/form/FormTextArea.vue";
import {useForm} from "vee-validate";
import * as Yup from "yup";
import FormTextAreaGrowable from "@/components/form/FormTextAreaGrowable.vue";

const props = defineProps({
  userCourseId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  isSubmitting: false,

  page: '',
  error: '',

  isScorePageExists: false,
  isTextPageExists: false,

  userCourse: null,

  scoreReview: [],
  textReviews: [],

  userScoreReview: {},
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, setFieldError, values} = useForm({
  validationSchema: Yup.object({}),
})

function setScoreReview(scoreId, score) {
  state.userScoreReview[scoreId] = score
}

onBeforeMount(async () => {
  const res = await appAxios.myCourses.fetchOne(props.userCourseId)
  const directories = store.state.user.directories

  if (Number(res.data.progress.completed_lessons_percent) !== 100) {
    await router.push({name: 'StudyCourse', params: {userCourseId: props.userCourseId}})
    return
  }

  const reviews = directories.review_item_list.filter(r => r.review_type === 'course')

  state.scoreReview = reviews
      .filter(r => !r.text_available)
      .sort((a, b) => a.order - b.order)

  state.textReviews = reviews
      .filter(r => r.text_available)
      .sort((a, b) => a.order - b.order)

  state.userCourse = res.data

  if (state.scoreReview.length) {
    state.page = 'score'
    state.isScorePageExists = true
  }

  if (state.textReviews.length) {
    if (!state.page) {
      state.page = 'text'
    }
    state.isTextPageExists = true
  }

  // state.page = 'final'
  state.course = res.data.course
})

async function sendResults() {
  const items = [
    ...Object.keys(state.userScoreReview).map(id => {
      return {
        integer_grade: state.userScoreReview[id],
        review_item_id: Number(id),
      }
    }),
    ...Object.keys(values).map(key => {
      return {
        text_grade: values[key],
        review_item_id: Number(key.replace('answer-', '')),
      }
    })
  ]
  await appAxios.myCourses.review(Number(state.userCourse.course.id), items)
  state.page = 'final'

  // const res =
  // review_items: [
  // {
  //   "text_grade": "string",
  //   "integer_grade": 0,
  //   "attache": "string",
  //   "review_item_id": 0,
  // }
  // ]
}

const onSubmit = handleSubmit(async values => {
  state.error = ''
  switch (state.page) {
    case 'score':
      if (state.scoreReview.length !== Object.keys(state.userScoreReview).length) {
        return state.error = 'Заполните анкету'
      }
      if (state.isTextPageExists) {
        return state.page = 'text'
      }
      state.isSubmitting = true
      await sendResults()
      state.isSubmitting = false
      break
    case 'text':
      const isValid = state.textReviews.every(review => {
        return values[`answer-${review.id}`]
      })
      if (!isValid) {
        return state.error = 'Заполните анкету'
      }
      state.isSubmitting = true
      await sendResults()
      state.isSubmitting = false
      break
  }
})

function back() {
  switch (state.page) {
    case 'score':
      break
    case 'text':
      if (state.isScorePageExists) {
        state.page = 'score'
        return
      }
      break
  }
  router.push({name: 'StudyCourse', params: {userCourseId: props.userCourseId}})
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  margin-bottom: -$homeLayoutPaddingBottom;
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 100;
  background: #171717;
  position: relative;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > * {
    flex-shrink: 0;
  }
}

.scoreBtnWrapper {
  display: flex;
  justify-content: space-between;

  & > * {
    margin: 3px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 28px !important;
    min-width: 28px !important;
    height: 28px !important;
    padding: 0 !important;
    @media (max-width: 399px) {
      width: 24px !important;
      min-width: 24px !important;
      height: 24px !important;
      margin: 1px;
    }
  }
}

.scoreActive {
  background: #CF2E2E !important;
}

.final {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .finalIcon {
    background-image: url("@/assets/icons/bigSuccess.svg");
    height: 112px;
    width: 112px;
  }
}

</style>
