import {appAxios} from '@/axios'
import {router} from '@/router/router'
import {wsBroadcast} from '@/store/wsBroadcast.js'
import {indexedStore} from "@/indexedDB/indexedStore.js"
import {yandexMetrika} from "@/external/yandexWebisor.js"
import {paymentStatuses, subscriptionStatuses} from '@/configs/subscriptionStatuses.js'

export const user = {
  namespaced: true,

  state: () => ({
    isRestoring: true,
    isAuthorized: false,
    token: null,
    profile: null,
    directories: null,
    roulette: null,
  }),

  getters: {
    subscriptionStatus(state) {
      return state.profile?.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
    },
    education(state) {
      return state.profile?.education?.at(0)
    },
    profileProgress(state) {
      const progress_fields_fullness = state.profile?.progress_fields_fullness
      const progress = {
        isFullFilled: false,
        isEnoughFilled: false,
        fullFilledCount: 0,
        enoughFilledCount: 0,
        fullFields: [
          {
            keys: ['products'],
            title: 'Продукты',
            route: {name: 'CheckListEditProducts'},
            isFilled: false,
          },
          {
            keys: ['userskill_set'],
            title: 'Ищу',
            route: {name: 'CheckListEditLookingFor'},
            isFilled: false,
          },
        ],
        enoughFields: [
          {
            keys: ['first_name', 'last_name', 'city', 'email'], // 'last_name'
            title: 'Личные данные',
            route: {name: 'CheckListEditPersonalData'},
            isFilled: false,
          },
          {
            keys: ['avatar'],
            title: 'Фото',
            route: {name: 'CheckListEditProfile'},
            isFilled: false,
          },
          {
            keys: ['profession_or_specialization_filled'],
            title: 'Роль',
            route: {name: 'CheckListEditProfessions'},
          },
          {
            keys: ['userniche_set'],
            title: 'Ниши',
            route: {name: 'CheckListEditNiches'},
            isFilled: false,
          },
          {
            keys: ['userachievement_set'],
            title: 'Кейсы и достижения',
            route: {name: 'CheckListEditAchievements'},
            isFilled: false,
          },
          {
            keys: ['usereducation_set'],
            title: 'Образование',
            route: {name: 'CheckListEditEducations'},
          },
          {
            keys: ['contact_info_filled'],
            title: 'Контакты',
            route: {name: 'CheckListEditContacts'},
          },
          {
            keys: ['about_me'],
            title: 'О себе',
            route: {name: 'CheckListEditDescription'},
            isFilled: false,
          },
        ],
      }
      if (!progress_fields_fullness) {
        return progress
      }
      progress.enoughFields.forEach((item) => {
        item.isFilled = item.keys.every(key => {
          return progress_fields_fullness[key]
        })
      })
      progress.fullFields.forEach((item) => {
        item.isFilled = item.keys.every(key => progress_fields_fullness[key])
      })
      progress.isEnoughFilled = progress.enoughFields.every(i => {
        return i.isFilled
      })
      progress.isFullFilled = progress.isEnoughFilled && progress.fullFields.every(i => {
        return i.isFilled
      })
      progress.enoughFields.forEach(i => {
        if (i.isFilled) {
          progress.enoughFilledCount++
        }
      })
      progress.fullFields.forEach(i => {
        if (i.isFilled) {
          progress.fullFilledCount++
        }
      })
      return progress
    }
  },
  mutations: {
    setIsRestoring(state, isRestoring) {
      state.isRestoring = isRestoring
    },
    setIsAuthorized(state, isAuthorized) {
      state.isAuthorized = isAuthorized
    },
    setToken(state, token) {
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }
      state.token = token
    },
    setProfile(state, profile) {
      if(profile?.subscriptions?.length) {
        const subscription = profile.subscriptions.at(0)
        const subscriptionStatus = subscription?.status
        if (subscriptionStatus === subscriptionStatuses.new) {
          const found = subscription.payments.find(payment => payment.status === paymentStatuses.pending)
          if (found) {
            subscription.status = subscriptionStatuses.pending
          }
        }
      }
      state.profile = {
        ...state.profile,
        ...profile,
      }
    },
    setDirectories(state, directories) {
      state.directories = directories
    },
  },
  actions: {
    async updateProfile(context, payload) {
      const data = {
        ...payload,
      }
      const res = await appAxios.user.updateMyProfile(data)
      context.commit('setProfile', res.data)
      return res
    },
    async restore(context) {
      context.commit('setIsRestoring', true)
      const token = localStorage.getItem('token')
      if (token) {
        await context.dispatch('auth', {token})
      }
      context.commit('setIsRestoring', false)
    },
    async login(context, payload) {
      const {phone, code} = payload
      try {
        const res = await appAxios.auth.checkAuthCode(phone, code)
        const token = res.data.token
        await context.dispatch('auth', {token})

        yandexMetrika('reachGoal', 'userAuthentication', {
          id: context.state.profile?.id,
        })
      } catch (e) {
        return {error: e}
      }
    },
    async auth(context, payload) {
      const {token} = payload
      context.commit('setToken', token)

      try {
        const res = await appAxios.user.fetchMyProfile()
        if (!res.data.length) {
          return await context.dispatch('logout')
        }
        context.commit('setProfile', res.data[0])

        await indexedStore.init(res.data[0].id)
        wsBroadcast.start()

        const res2 = await appAxios.user.fetchDirectories()
        context.commit('setDirectories', res2.data)

        OneSignalDeferred.push(function () {
          OneSignal.login(res.data[0].id.toString())
        })

        yandexMetrika('setUserID', res.data[0].id.toString())
        yandexMetrika('reachGoal', 'userAuthorization', {
          id: context.state.profile?.id,
        })

        context.commit('setIsAuthorized', true)
        if (router.currentRoute.value.query.returnTo) {
          const url = new URL(`${window.location.protocol}//${window.location.hostname}${decodeURIComponent(router.currentRoute.value.query.returnTo)}`)
          await router.push({
            path: url.pathname,
            query: Object.fromEntries(url.searchParams.entries()),
          })
        } else {
          await router.push({name: 'Home'})
        }
      } catch (e) {
        console.log('e', e)
        await context.dispatch('logout')
        return e
      }
    },
    async logout(context) {
      context.commit('setIsAuthorized', null)
      context.commit('setToken', null)
      await router.push({name: 'LogIn'})
      context.commit('setProfile', null)
      wsBroadcast.stop()

      OneSignalDeferred.push(function () {
        OneSignal.logout()
      })
    },
  }
}
