<template>
  <div class="fair pageWrapper">
    <head-new
        page_title="Курсы"
        :back-action="() => router.push({name: 'Home'})"
    />
    <MarketplaceTabs/>

<!--    <BuySubscription-->
<!--        :shown="!!state.selectedCourse"-->
<!--        @close="state.selectedCourse = null"-->
<!--    />-->

    <div class="main_container coursesWrapper pageWrapperOverflow">

      <BlockSubscription
          style="margin: 0 0 4px 0"
          :showAtStatuses="{[subscriptionStatuses.new]: true, [subscriptionStatuses.pending]: true}"
          :iconSize="40"
          newSubscriptionTitle="Получи доступ к базе знаний с подпиской Younesis Premium"
          newSubscriptionText="Смотри обучающие материалы, выполняй задания, наращивай свои знания и повышай экспертность"
          newSubscriptionButton="Подключить подписку"
          :newSubscriptionAction="openSubscription"
          pendingSubscriptionTitle="Получи доступ к базе знаний с подпиской Younesis Premium"
          pendingSubscriptionText="Смотри обучающие материалы, выполняй задания, наращивай свои знания и повышай экспертность"
          pendingSubscriptionButton="Подключить подписку"
          :pendingSubscriptionAction="openSubscription"
      />

      <div v-if="state.isLoading" class="course" v-for="_ in [1,2,3,4,5]">
        <div class="courseInfo">
          <Skeleton
              width="80"
              height="80"
              radius="8"
          />
          <div>
            <Skeleton
                width="180"
                :style="{marginBottom: '8px'}"
            />
            <Skeleton
                width="48"
                height="12"
            />
          </div>
        </div>
        <div class="buttons">
          <Skeleton
              full-width
              height="36"
          />
          <Skeleton
              full-width
              height="36"
          />
        </div>
      </div>

      <div
          v-if="!state.isLoading"
          class="course"
          v-for="item in state.courses"
      >
        <div class="courseInfo">
          <div
              class="imgWrapper"
              :class="{
                blur: item.course?.status === coursesStatuses.inactive || item.student_course?.status === coursesStatuses.inactive,
                full: item.course?.preview_cover_image || item.student_course?.course?.preview_cover_image,
              }"
          >
            <template v-if="item.course">
              <div
                  v-if="item.course.preview_cover_image"
                  class="img"
                  :class="{blur: item.course.status === coursesStatuses.inactive}"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.course.preview_cover_image))}"
              />
              <div
                  v-else
                  class="img"
                  :class="{blur: item.course.status === coursesStatuses.inactive}"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.course.preview_image))}"
              />
            </template>
            <template v-if="item.student_course">
              <div
                  v-if="item.student_course.course.preview_cover_image"
                  class="img"
                  :class="{blur: item.student_course.status === coursesStatuses.inactive}"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.student_course.course.preview_cover_image))}"
              />
              <div
                  v-else
                  class="img"
                  :class="{blur: item.student_course.status === coursesStatuses.inactive}"
                  :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.student_course.course.preview_image))}"
              />
            </template>
            <div
                v-if="item.course?.status === coursesStatuses.inactive || item.student_course?.status === coursesStatuses.inactive"
                class="inactiveCourse"
            >
              <Typography
                  variant="h1"
                  style="font-weight: 500"
              >
                Скоро добавится
              </Typography>
            </div>
          </div>
          <template v-if="item.course">
            <div>
              <div class="title">
                {{ item.course.title }}
              </div>
              <div class="desc">
                <Typography
                    variant="text2"
                    color="#FFF"
                >
                  {{ item.course.description }}
                </Typography>
              </div>
            </div>
          </template>
          <template v-if="item.student_course">
            <div>
              <div class="title">{{ item.student_course.course.title }}</div>
              <div class="desc">
                <Typography
                    variant="text2"
                    color="#FFF"
                >
                  {{ item.student_course.course.description }}
                </Typography>
              </div>
            </div>
          </template>
        </div>

        <div class="buttons">
          <template v-if="item.course">
            <template v-if="state.isSubscriptionActive">
              <AppButton
                  variant="primary"
                  size="small"
                  full-width
                  @click="enroll(item.course)"
                  :loading="item.course.isLoading"
                  :disabled="item.course.isLoading"
              >
                Начать курс
              </AppButton>
            </template>
            <template v-else>
              <AppButton
                  variant="secondary"
                  size="small"
                  full-width
                  @click="() => router.push({name: 'MarketplaceCourseLanding', params: {courseId: item.course.id}})"
              >
                Подробнее
              </AppButton>
<!--              <AppButton-->
<!--                  v-if="item.course.status !== coursesStatuses.inactive"-->
<!--                  variant="primary"-->
<!--                  size="small"-->
<!--                  full-width-->
<!--                  @click="state.selectedCourse = item.course"-->
<!--                  style="padding: 0"-->
<!--              >-->
<!--                Получить доступ-->
<!--              </AppButton>-->
            </template>
          </template>
          <template v-if="item.student_course">
            <AppButton
                variant="primary"
                size="small"
                full-width
                @click="() => router.push({name: 'StudyCourse', params: {userCourseId: item.student_course.id}})"
            >
              Перейти
            </AppButton>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import HeadNew from '@/components/Head.vue'
import MarketplaceTabs from '@/views/study/StudyTabs.vue'
import {computed, onBeforeMount, reactive, watch} from 'vue'
import {appAxios} from '@/axios'
import AppButton from '@/components/UI/AppButton.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import Skeleton from '@/components/UI/Skeleton.vue'
import {router} from '@/router/router'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Typography from '@/components/UI/Typography.vue'
import store from '@/store/store.js'
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'
import {coursesStatuses, studentCoursesStatuses} from "@/configs/coursesStatuses.js"
import BlockSubscription from '@/views/subscriptions/BlockSubscription.vue'

const state = reactive({
  isLoading: true,
  isSubscriptionLoading: false,
  isSubscriptionActive: false,
  selectedCourse: null,
  courses: [],
})

async function enroll(course) {
  try {
    course.isLoading = true
    const res = await appAxios.course.enroll(course.id)
    await router.push({
      name: 'StudyCourse',
      params: {
        userCourseId: res.data.id
      }
    })
  } catch (e) {
    if (e?.response?.data?.detail === 'Вы уже записаны на этот курс') {
      // todo: ?
    }
    throw e
  }
  course.isLoading = false
}

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  if(subscriptions) {
    state.subscription = subscriptions?.at(0)
    const userSubscription = store.state.user.profile?.subscriptions[0]
    if (userSubscription && userSubscription.status === subscriptionStatuses.active) {
      state.isSubscriptionActive = true
    }
  }
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.course.fetchAll()
    state.courses = res.data
    state.subscription = store.state.user.directories.subscription_price_list[0]

    // state.isSubscriptionActive
    const userSubscription = store.state.user.profile?.subscriptions[0]
    if (userSubscription && userSubscription.status === subscriptionStatuses.active) {
      state.isSubscriptionActive = true
    }
  } catch (e) {
    console.log(e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

async function openSubscription() {
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
    returnName: 'MarketplaceCourses',
  }))
  await router.push({
    name: 'SubscriptionTariffSelection',
  })
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100dvh);
  max-height: calc(100dvh);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  margin-bottom: 80px;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-shrink: 0;
  }
}

.inactiveCourse {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.desc {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.coursesWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px !important;
  padding-bottom: 24px;

  .course {
    overflow: hidden;
    background: #222222;
    padding: 12px;
    border-radius: 16px;

    .courseInfo {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .imgWrapper {
        position: relative;
        margin: -12px -12px 0 -12px;
        height: 152px;
        border-radius: 16px 16px 0 0;
        display: flex;
        justify-content: center;
        background: #3B3B3B;
        padding: 4px;

        &.blur {
          background: #2d2d2d;
        }

        .img {
          width: 144px;
          height: 144px;
          border-radius: 8px;
          background-repeat: no-repeat;
          background-size: cover;

          &.blur {
            filter: blur(10px);
          }
        }

        &.full {
          height: 144px;
          padding: 0;

          .img {
            width: 100%;
            height: 144px;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        }
      }
    }

    .buttons {
      margin-top: 12px;
      display: flex;
      gap: 12px;
    }
  }
}
</style>
