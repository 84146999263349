<template>
  <div>
    <AnotherUserProfileModal

        fullHeight
        v-if="state.loadedUser"
        :user="state.loadedUser"
        :userContact="state.loadedUser"
        @close="$emit('close')"
    />
    <div v-else>
      <div v-if="user" class="modal">

      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive, watch} from 'vue'
import {appAxios} from "@/axios.js"
import AnotherUserProfileModal from "@/views/profile/AnotherUserProfileModal.vue"

const emit = defineEmits(['close'])

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  userContact: {
    type: Object,
    default: null,
  },
  fullHeight: {
    type: Boolean,
    default: null,
  }
})

const state = reactive({
  loadedUser: null,
})

watch(() => props.user, async (user) => {
  console.log('>', props)
  if (user && user.id) {
    try {
      const res = await appAxios.user.fetchProfile(user.id)

      state.loadedUser = res.data
      console.log('state.loadedUser', state.loadedUser)

    } catch (e) {
      //state.loadedUser
    }
  } else {
    state.loadedUser = null
  }
})

function back() {
  emit('close')
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.modal {
  position: fixed;
  z-index: 1001;
  background: #171717;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

</style>
