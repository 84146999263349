<template>
  <head-new
      page_title=""
      :back-action="() => {
        router.push({name: $route.meta.returnName})
      }"
  />
<!--  :rightAction="submitShare"-->
<!--  :rightIcon="canShare && !state.isLoading ? require(`@/assets/icons/white_24_share.svg`) : null"-->

  <PreviewDrawer
      :route-name="'AnotherUsersProfile'"
  />

<!--  <BottomDrawer-->
<!--      v-if="!state.isLoading"-->
<!--      :is-shown="state.isContactsShown"-->
<!--      title="Контакты"-->
<!--      @close="state.isContactsShown = false"-->
<!--      class=""-->
<!--  >-->
<!--    <div class="contacts">-->

<!--      <div v-if="state.user.contact_telegram" class="contact">-->
<!--        <Typography variant="body1">-->
<!--          Telegram-->
<!--        </Typography>-->

<!--        <div class="test">-->
<!--          <Typography variant="body1" style="text-align: right">-->
<!--            {{ state.user.contact_telegram }}-->
<!--          </Typography>-->

<!--          <AppButton-->
<!--              variant="transparent"-->
<!--              size="small"-->
<!--              @click="copyToClipboard(state.user.contact_telegram, $event)"-->
<!--          >-->
<!--            <Icon :icon="require(`@/assets/icons/gray_16_copy.svg`)"/>-->
<!--          </AppButton>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div v-if="state.user.contact_instagram" class="contact">-->
<!--        <Typography variant="body1">-->
<!--          Instagram*-->
<!--        </Typography>-->

<!--        <div class="test">-->
<!--          <Typography variant="body1" style="text-align: right">-->
<!--            {{ state.user.contact_instagram }}-->
<!--          </Typography>-->

<!--          <AppButton-->
<!--              variant="transparent"-->
<!--              size="small"-->
<!--              @click="copyToClipboard(state.user.contact_instagram, $event)"-->
<!--          >-->
<!--            <Icon :icon="require(`@/assets/icons/gray_16_copy.svg`)"/>-->
<!--          </AppButton>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div v-if="state.user.contact_tiktok" class="contact">-->
<!--        <Typography variant="body1">-->
<!--          TikTok-->
<!--        </Typography>-->

<!--        <div class="test">-->
<!--          <Typography variant="body1" style="text-align: right">-->
<!--            {{ state.user.contact_tiktok }}-->
<!--          </Typography>-->

<!--          <AppButton-->
<!--              variant="transparent"-->
<!--              size="small"-->
<!--              @click="copyToClipboard(state.user.contact_tiktok, $event)"-->
<!--          >-->
<!--            <Icon :icon="require(`@/assets/icons/gray_16_copy.svg`)"/>-->
<!--          </AppButton>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div v-if="state.user.contact_youtube" class="contact">-->
<!--        <Typography variant="body1">-->
<!--          YouTube-->
<!--        </Typography>-->

<!--        <div class="test">-->
<!--          <Typography variant="body1" style="text-align: right">-->
<!--            {{ state.user.contact_youtube }}-->
<!--          </Typography>-->

<!--          <AppButton-->
<!--              variant="transparent"-->
<!--              size="small"-->
<!--              @click="copyToClipboard(state.user.contact_youtube, $event)"-->
<!--          >-->
<!--            <Icon :icon="require(`@/assets/icons/gray_16_copy.svg`)"/>-->
<!--          </AppButton>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div v-if="state.user.contact_vk" class="contact">-->
<!--        <Typography variant="body1">-->
<!--          VK-->
<!--        </Typography>-->

<!--        <div class="test">-->
<!--          <Typography variant="body1" style="text-align: right">-->
<!--            {{ state.user.contact_vk }}-->
<!--          </Typography>-->

<!--          <AppButton-->
<!--              variant="transparent"-->
<!--              size="small"-->
<!--              @click="copyToClipboard(state.user.contact_vk, $event)"-->
<!--          >-->
<!--            <Icon :icon="require(`@/assets/icons/gray_16_copy.svg`)"/>-->
<!--          </AppButton>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div v-if="state.user.contact_phone" class="contact">-->
<!--        <Typography variant="body1">-->
<!--          Телефон-->
<!--        </Typography>-->

<!--        <div class="test">-->
<!--          <Typography variant="body1" style="text-align: right">-->
<!--            {{ state.user.contact_phone }}-->
<!--          </Typography>-->

<!--          <AppButton-->
<!--              variant="transparent"-->
<!--              size="small"-->
<!--              @click="copyToClipboard(state.user.contact_phone, $event)"-->
<!--          >-->
<!--            <Icon :icon="require(`@/assets/icons/gray_16_copy.svg`)"/>-->
<!--          </AppButton>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <Typography v-if="state.user.contact_instagram" variant="text2">-->
<!--      *Instagram – продукт компании Meta, признанная экстремистской организацией в России-->
<!--    </Typography>-->

<!--    <div style="height: 32px"/>-->

<!--  </BottomDrawer>-->

  <BottomDrawer
      v-if="!state.isLoading"
      :isShown="state.isAddingContactShown"
      :title="state.userContact ? 'Удаление из визитницы' : 'Добавить в визитницу'"
      @close="state.isAddingContactShown = false"
  >
    <Typography variant="v14-600" color="#D3D2D2">
      Ты действительно хочешь удалить
      {{ state.user.first_name }} {{ state.user.last_name }}
      из визитницы?
    </Typography>

    <div style="display: flex; gap: 4px; margin: 16px 0">
      <appButton
          full-width
          class="submitButton"
          variant="secondary"
          :disabled="state.isDeleting"
          @click="state.userContactShown = false"
      >
        Отменить
      </appButton>
      <appButton
          full-width
          class="submitButton"
          variant="secondary"
          color="#FF303A"
          :disabled="state.isDeleting"
          :loading="state.isDeleting"
          @click="() => evaluated(0)"
      >
        Удалить
      </appButton>
    </div>
  </BottomDrawer>

  <BottomDrawer
      v-if="!state.isLoading"
      :is-shown="state.isSelectedProduct"
      @close="state.isSelectedProduct = null"
      closeButtonOutside
  >
    <div v-if="state.isSelectedProduct" class="productInfo">
      <div v-if="state.isSelectedProduct.image" class="imgWrapper full">
        <div
            class="img"
            :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(state.isSelectedProduct.image))}"
        />
      </div>

      <div>
        <div class="title">
          {{ state.isSelectedProduct.title }}
        </div>
        <Typography variant="body1">
          {{ state.isSelectedProduct.description }}
        </Typography>
      </div>

      <!--      <AppButton-->
      <!--          class="share"-->
      <!--          size="small"-->
      <!--          variant="secondary"-->
      <!--          @click="submitShare(state.isSelectedProduct)"-->
      <!--      >-->
      <!--        <Icon :icon="require(`@/assets/icons/gray_24_share.svg`)"/>-->
      <!--        Поделиться-->
      <!--      </AppButton>-->

      <div class="bottom drawer">
        <Avatar
            size="tiny"
            :image="state.user?.preview_avatar"
        />

        <div style="flex-grow: 1; flex-shrink: 1;">
          <Typography base="span" variant="body1" color="#D3D2D2">
            {{ state.user.first_name }}
          </Typography>
          {{ ' ' }}
          <Typography base="span" variant="body1" color="#D3D2D2">
            {{ state.user.last_name }}
          </Typography>
        </div>

        <AppButton
            size="small"
            @click="applyForProduct(state.isSelectedProduct)"
        >
          Написать
        </AppButton>
      </div>
    </div>
  </BottomDrawer>

  <div class="main_container userData">
    <Avatar
        v-if="!state.isLoading"
        size="large"
        :image="imagesLinkCache.cache(state.user?.preview_avatar)"
        v-fullscreen-image="{
          imageUrl: [imagesLinkCache.cache(state.user?.avatar)],
          zoom: true,
        }"
    />
    <Skeleton
        v-else
        variant="text"
        height="106"
        width="106"
        radius="16"
    />

    <div class="userInfo">
      <Typography v-if="!state.isLoading" variant="title">
        {{ state.user?.first_name }} {{ state.user?.last_name }}
      </Typography>
      <Skeleton
          v-else
          variant="text"
          height="24"
          width="200"
      />

      <AppButton
          v-if="!state.isLoading"
          variant="secondary"
          size="small"
          @click="startChat(userId)"
      >
        <Icon :icon="require(`@/assets/icons/white_20_chat.svg`)"/>
        Написать
      </AppButton>
      <Skeleton
          v-else
          width="126"
          height="36"
      />

    </div>
  </div>

  <div class="main_container">

    <UserLocationAndAge
        v-if="!state.isLoading"
        :city="state.user?.city"
        :birthDate="state.user?.birth_date"
    />

    <UserProfessions
        class="userProfile"
        v-if="!state.isLoading"
        :professions="state.user?.professions"
    />

    <Typography v-if="!state.isLoading" preLine variant="body1">
      {{ state.user?.about_me }}
    </Typography>
    <Skeleton
        v-for="i in 3"
        variant="text"
        :width="String(200 + (i % 2) * 50 + (i * 20))"
        height="14"
        v-else
    />
  </div>

  <div class="main_container buttons">

    <AppButton
        v-if="state.user && (state.user.contact_telegram || state.user.contact_instagram || state.user.contact_youtube || state.user.contact_tiktok || state.user.contact_vk || state.user.contact_phone)"
        :is-loading="state.isLoading"
        full-width
        variant="secondary"
        @click="() => state.isContactsShown = true"
    >
      <Icon :icon="require(`@/assets/icons/white_24_phone.svg`)"/>
      Показать контакты
    </AppButton>

    <AppButton
        v-if="!state.userContact"
        :is-loading="state.isLoading"
        full-width
        variant="primary"
        @click="() => state.isAddingContactShown = true"
    >
      <!--      <Icon :icon="require(`@/assets/icons/white_24_star.svg`)"/>-->
      Добавить в визитницу
    </AppButton>
    <AppButton
        v-else
        :is-loading="state.isLoading"
        full-width
        variant="secondary"
        @click="() => state.isAddingContactShown = true"
    >
      <Icon :icon="require(`@/assets/icons/white_24_filledStar.svg`)"/>
      <!--      {{ state.userContact.rating }} • -->
      В визитнице
    </AppButton>
  </div>
  <div class="main_container">

    <BlockNiches
        v-if="state.user?.niches.length"
        :niches="state.user?.niches"
    />

    <BlockLookingFor
        v-if="state.user?.looking_for.length"
        :lookingFor="state.user?.looking_for"
        @applyForSkill="applyForSkill"
    />

    <BlockAchievements
        v-if="state.user?.achievements.length"
        :achievements="state.user?.achievements"
    />

    <BlockProducts
        v-if="state.user?.products.length"
        :products="state.user?.products"
        :canBeObtained="true"
        @selectProduct="product => state.isSelectedProduct = product"
    />

    <BlockEducations
        v-if="state.user?.education[0] && (state.user?.education[0].courses.length || state.user?.education[0].communities.length || state.user?.education[0].institutions.length)"
        :education="state.user?.education"
    />

  </div>
</template>

<script setup>
import {router} from '@/router/router'
import HeadNew from '@/components/Head.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import Icon from '@/components/UI/Icon.vue'
import AppButton from '@/components/UI/AppButton.vue'
import Avatar from '@/components/profile/Avatar.vue'
import Typography from '@/components/UI/Typography.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import UserCard from '@/components/UserCard.vue'
import Evaluation from '@/components/Evaluation.vue'
import UserProfessions from '@/components/UserProfessions.vue'
import UserLocationAndAge from '@/components/UserLocationAndAge.vue'
import BlockNiches from '@/components/BlockNiches.vue'
import BlockAchievements from '@/components/BlockAchievements.vue'
import {copyToClipboard} from '@/utils/copyToClipboard'
import BlockLookingFor from '@/components/BlockLookingFor.vue'
import BlockProducts from '@/components/BlockProducts.vue'
import BlockEducations from '@/components/BlockEducations.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {useShare} from '@/hooks/useShare'
import {useRoute} from 'vue-router'
import store from '@/store/store'
import {createSendMessageByType} from '@/utils/createSendMessageByType'
import PreviewDrawer from '@/views/auth/preview/PreviewDrawer.vue'

const route = useRoute()
const {canShare, share} = useShare()

const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
})

const state = reactive({
  isLoading: true,
  user: null,
  userContact: null,

  isSelectedProduct: null,
  // isContactsShown: false,
  isAddingContactShown: false,

  isDeleting: false,
})

async function submitShare() {
  const url = new URL(window.location.href)
  url.pathname = route.fullPath
  await share({
    title: `${state.user.first_name} ${state.user.last_name}`,
    description: state.user.about_me,
    url: url.toString(),
  })
}

onBeforeMount(async () => {
  try {
    // todo: remove myContacts fetching
    const [userRes, contactsRes] = await Promise.all([
      appAxios.guest.getUser(props.userId),
    ])

    state.user = userRes.data
  } catch (e) {
    console.log('error', e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

async function evaluated(rate) {
  try {
    let userContact = null
    if (!state.userContact) {
      const res = await appAxios.myContacts.addContact(state.user.id, rate)
      userContact = res.data
    } else if (rate === 0) {
      state.isDeleting = true
      await appAxios.myContacts.removeContact(state.userContact.id)
      state.isDeleting = false
    } else {
      const res = await appAxios.myContacts.updateContact(state.userContact.id, rate)
      userContact = res.data
    }
    state.userContact = userContact
    state.isAddingContactShown = false
  } catch (e) {
    console.error(e)
  }
}

async function applyForSkill(skill) {
  const user = state.user
  const uid = createSendMessageByType.lookingFor({user, skill})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function applyForProduct(product) {
  const user = state.user
  const uid = createSendMessageByType.product({user, product})
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId: user.id, sentUID: uid},
  })
}

async function startChat(userId) {
  await router.push({
    name: route.meta.chatName ?? 'UserChat',
    params: {userId},
  })
}

</script>

<style lang="scss" scoped>

.userData {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 24px;
}

.userProfile {
  margin-bottom: 8px;
  margin-top: 12px;
}

//.contacts {
//  display: flex;
//  flex-direction: column;
//  margin-bottom: 8px;
//
//  .contact {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    padding: 6px 0;
//    border-bottom: 1px solid #2B2B2B;
//  }
//
//  .contact:last-child {
//    border-bottom: none;
//  }
//
//  .test {
//    display: flex;
//    align-items: center;
//  }
//}

.productInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .imgWrapper {
    width: 100%;
    height: 116px;
    border-radius: 8px;
    background: #3B3B3B;

    .img {
      width: 100%;
      height: 116px;
      flex-shrink: 0;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .bottom {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    margin-top: 4px;
    border-top: 1px solid #494949;
    align-items: center;

    & > * {
      flex-shrink: 0;
    }

    &.drawer {
      margin: 0 -16px;
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.share {
  margin-top: 20px;
  margin-bottom: 12px;
}

</style>
