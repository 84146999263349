import { StatusBar, Style } from '@capacitor/status-bar'
import { NavigationBar } from "@capgo/capacitor-navigation-bar";

window.StatusBar = StatusBar
window.NavigationBar = NavigationBar

StatusBar.setBackgroundColor({color: "171717"})
NavigationBar.setNavigationBarColor({color: '171717'})

// iOS only
// window.addEventListener('statusTap', function () {
//   console.log('statusbar tapped')
// })
//
// // Display content under transparent status bar (Android only)
// StatusBar.setOverlaysWebView({ overlay: false })
//
// const setStatusBarStyleDark = async () => {
//   await StatusBar.setStyle({ style: Style.Dark })
// }
//
// const setStatusBarStyleLight = async () => {
//   await StatusBar.setStyle({ style: Style.Light })
// }
//
// const hideStatusBar = async () => {
//   await StatusBar.hide()
// }
//
// const showStatusBar = async () => {
//   await StatusBar.show()
// }

export {StatusBar}
